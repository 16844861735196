import React from "react"
import hexagonoAmarillo from "@components/pageNosotros/imgNosotros/hexagonoAmarillo.png"
import hexagonoAzul from "@components/pageNosotros/imgNosotros/hexagonoAzul.png"
import hexagonoAzulOscuro from "@components/pageNosotros/imgNosotros/hexagonoAzulOscuro.png"


const StartPricing = () => {
  return (
    <div className="start-hero-home">
      <div className="text-message-nosotros">
        “Usamos la tecnología e innovación como dos poderes para generar cambios
        en las empresas.”
      </div>
      <br />
      <div className="container-hexagonos-nosotros">
        <img
          src={hexagonoAmarillo}
          alt="icon-hexagono"
          className="hexagonoOne"
        />
        <img
          src={hexagonoAzulOscuro}
          alt="icon-hexagono"
          className="hexagonoTwo"
        />
        <img
          src={hexagonoAzulOscuro}
          alt="icon-hexagono"
          className="hexagonoThree"
        />
        <img
          src={hexagonoAmarillo}
          alt="icon-hexagono"
          className="hexagonoFour"
        />
        <img
          src={hexagonoAmarillo}
          alt="icon-hexagono"
          className="hexagonoFive"
        />
        <img
          src={hexagonoAzul}
          alt="icon-hexagono"
          className="hexagonoSix"
        />
        <img
          src={hexagonoAmarillo}
          alt="icon-hexagono"
          className="hexagonoSeven"
        />
        <img
          src={hexagonoAzulOscuro}
          alt="icon-hexagono"
          className="hexagonoEight"
        />
        <img
          src={hexagonoAzulOscuro}
          alt="icon-hexagono"
          className="hexagonoNine"
        />
        <img
          src={hexagonoAzulOscuro}
          alt="icon-hexagono"
          className="hexagonoTen"
        />
      </div>
    </div>
  )
}

export default StartPricing
