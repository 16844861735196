import React from "react"
//fotos slider
import Foto1 from "@components/pageNosotros/imgNosotros/img-slider/foto1.png"
import Foto2 from "@components/pageNosotros/imgNosotros/img-slider/foto2.png"
import Foto3 from "@components/pageNosotros/imgNosotros/img-slider/foto3.png"
import Foto4 from "@components/pageNosotros/imgNosotros/img-slider/foto4.png"
import Foto5 from "@components/pageNosotros/imgNosotros/img-slider/foto5.png"
import Foto6 from "@components/pageNosotros/imgNosotros/img-slider/foto6.png"
import Foto7 from "@components/pageNosotros/imgNosotros/img-slider/foto7.png"
import Foto8 from "@components/pageNosotros/imgNosotros/img-slider/foto8.png"
import Foto9 from "@components/pageNosotros/imgNosotros/img-slider/foto9.png"
import Foto10 from "@components/pageNosotros/imgNosotros/img-slider/foto10.png"
import Foto11 from "@components/pageNosotros/imgNosotros/img-slider/foto11.png"


import libro from "@components/pageNosotros/imgNosotros/icon-aboutUs/libro.svg"
import sombrilla from "@components/pageNosotros/imgNosotros/icon-aboutUs/sombrilla.svg"
import tiro from "@components/pageNosotros/imgNosotros/icon-aboutUs/tiro.svg"

//img ADN
import imgOne from "@components/pageNosotros/imgNosotros/img-adn/imgOne.png"
import imgTwo from "@components/pageNosotros/imgNosotros/img-adn/imgTwo.png"
import imgThree from "@components/pageNosotros/imgNosotros/img-adn/imgThree.png"


//images history
import img2014 from "@components/pageNosotros/imgNosotros/img-history/2014.png"
import img2016 from "@components/pageNosotros/imgNosotros/img-history/2016.png"
import img2018 from "@components/pageNosotros/imgNosotros/img-history/2018.png"
import img2019 from "@components/pageNosotros/imgNosotros/img-history/2019.png"
import img2020 from "@components/pageNosotros/imgNosotros/img-history/2020.png"
import img2022 from "@components/pageNosotros/imgNosotros/img-history/2022.png"
import img2023 from "@components/pageNosotros/imgNosotros/img-history/2023.png"

const textBanner = (
  <span>
    “Hacemos que las empresas{" "}
    <span className="text-banner-nosotros">contacten y se comuniquen</span> con
    sus clientes, de la mejor manera, con{" "}
    <span className="text-banner-nosotros">innovación y tecnología.</span>”
  </span>
)

let data = {
  start: {
    textbody: textBanner,
  },

  aboutus: [
    {
      icon: libro,
      title: "Eliminamos el “confort” del diccionario",
      text:
        "No nos gusta la conformidad, por eso siempre estamos aprendiendo cosas nuevas que nos ayuden a mejorar los proyectos.",
    },
    {
      icon: sombrilla,
      title: "Nos conectamos donde estemos",
      text:
        "Así sea remoto o en la ofi, es lo de menos. Lo importante es que el sentimiento de trabajo en equipo nunca se desconecta.",
    },
    {
      icon: tiro,
      title: "Promovemos la autonomía en nuestro equipo",
      text:
        "Trabajamos de manera independiente, confiamos en que cada equipo siempre llegará a las metas establecidas. ¡Nos hace más ágiles!",
    },
  ],

  adn: [
    {
      img: imgOne,
      title: "Así de cool somos",
      text:
        "Los cimientos de nuestra cultura organizacional están sobre la agilidad porque nos ha permito crear productos que la rompen en el mercado.",
    },
    {
      img: imgTwo,
      title: "Nuestra motivación",
      text:
        "Queremos que nuestras soluciones tecnológicas trasciendan todas las fronteras. Por eso, apuntamos ser una empresa global de tecnología centrada en las personas. ",
    },
    {
      img: imgThree,
      title: "Para qué existimos",
      text:
        "Nunca parar en buscar nuevas formas de solucionar los puntos de dolor de los negocios mediante la creación de productos y servicios tecnológicos. Todo esto, a través de una cultura ágil. ",
    },
  ],

  gallery: [
    Foto1,
    Foto2,
    Foto3,
    Foto4,
    Foto5,
    Foto6,
    Foto7,
    Foto8,
    Foto9,
    Foto10,
    Foto11,
    Foto1,
    Foto2,
    Foto3,
    Foto4,
    Foto5,
    Foto6,
    Foto7,
    Foto8,
    Foto9,
    Foto10,
    Foto11,
    Foto1,
    Foto2,
    Foto3,
    Foto4,
    Foto5,
    Foto6,
    Foto7,
    Foto8,
    Foto9,
    Foto10,
    Foto11,
  ],

history: [
  {
    img: img2014,
    title: "2014",
    text:
      "Frank y Max fundan Securitec, con la idea principal de ayudar al crecimiento de las empresas con operaciones de Contact Center en LATAM a través de la tecnología e innovación.",
  },
  {
    img: img2016,
    title: "2016",
    text:
      "Sacamos al mercado Score, nuestra plataforma en la nube para Call Centers. Se sumaron nuestros actuales CTO y COO, Daniel y Raúl. Además, tuvimos un crecimiento del 80% en nuestra cartera de clientes.",
  },
  {
    img: img2018,
    title: "2018",
    text:
      "Score marca un nombre, siendo la única plataforma en la nube para Call Centers creada por una empresa peruana. Se suma PlatCon, nuestra plataforma de contactabilidad para validación de móviles.",
  },
  {
    img: img2019,
    title: "2019",
    text:
      "Nos transformamos, de solo ayudar a operaciones de Contact Centers, en ayudar a toda operación de Atención al Cliente, Telemarketing, Televentas o Cobranzas. Mejoramos nuestro marcador predictivo, ahora con Machine Learning.",
  },
  {
    img: img2020,
    title: "2020",
    text:
      "Ganamos el concurso 'Innovación empresarial' de ProInnovate, cofinanciando Score como software escalable en la nube. Nace Ticker, plataforma omnicanal que brinda una experiencia única en atención al cliente.",
  },
  {
    img: img2022,
    title: "2022",
    text:
      "Potenciamos nuestro marketing y ventas. Alcanzando objetivos que nos han permitido escalar en LATAM con licenciamientos a clientes top. Doblamos la apuesta por el SEO y content marketing, siendo nuestro canal principal de adquisición.",
  },
  {
    img: img2023,
    title: "2023",
    text:
      "¿Qué se viene? La nueva versión de Score en todo LATAM, abrir oficinas en mercados a los que ya les vendemos y multiplicar nuestras ventas por Q. ¡NO HAY LÍMITES! 💛",
  },
],

  contacts: {
    title: <p>¿Quieres saber mas sobre Beex? <br /></p>,
    textbody: <p>Comunicate con uno de nuestros agentes <br /><br /> <br /></p>,
    href: "/hablemos/",
    btn: "Conectar con ventas",
  },
}
export default data
