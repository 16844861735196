import React, { useEffect, useRef } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"

const Customer = ({ data }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  //funcion para slider de logos clientes
  let settings = {
    infinite: true,
    slidesToShow: 5,
    initialSlide: 0,
    autoplay: true,
    cssEase: "linear",
    autoplaySpeed: 0, // 1000
    pauseOnFocus: false,
    pauseOnHover: false,
    slidesToScroll: 5,
    speed: 13000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          initialSlide: 0,
        },
      },
    ],
  }
  return (
    <section className="section-galery" ref={fromRef}>
      <div className="center-text container-title-galery-support" style={{textAlign: "center"}}>
        <p className="title-galery-support titles">Más fotitos 📸 </p>
      </div>
      <div id="scroll-textOne" className="text-slider-galery-fondo">
        NUESTRO TALENTO
      </div>
      <div className="silder-galery">
      <Slider {...settings} ref={sliderRef}>
        {data.map(elemento => {
          return (
            <div key={elemento} className="img-galery">
              <img loading="lazy" className="" src={elemento} alt="galery" />
            </div>
          )
        })}
      </Slider>
      </div>
      <div id="scroll-textTwo" className="text-slider-galery-fondo">
        TECNOLOGÍA <div/> INNOVACIÓN
      </div>
    </section>
  )
}
export default Customer
