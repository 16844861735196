import React from "react"
import TemplatePageNosotros from "@components/pageNosotros/template"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Conoce al equipo de Beex [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/nosotros/`}
      />
      <meta name="robots" content="noindex, follow"></meta>
      <meta charSet="utf-8" />
      <meta name="description" content="Conoce al equipo de Beex" />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Conoce al equipo de Beex" />
      <meta
        property="og:url"
        content={`https://beexcc.com/nosotros/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta property="og:description" content="Conoce al equipo de Beex" />
    </Helmet>
    <TemplatePageNosotros location={location} />
  </div>
)

export default IndexPage
