import React from "react"
import foto from "@components/pageNosotros/imgNosotros/fotoequipo.png"
import Typed from "react-typed"
import burbujaOne from "@components/pageNosotros/imgNosotros/icon-aboutUs/burbujaOne.png"
import burbujaTwo from "@components/pageNosotros/imgNosotros/icon-aboutUs/burbujaTwo.png"
import burbujaThree from "@components/pageNosotros/imgNosotros/icon-aboutUs/burbujaThree.png"
import burbujaFour from "@components/pageNosotros/imgNosotros/icon-aboutUs/burbujaFour.png"

const Intro = () => {
  return (
    <div className="section-nosotros">
      <div className="banner-nosotros">
        <div className="container-title-nosotros">
          <p className="title-nosotros">
            Somos{" "}
            <Typed
              strings={[
                "<span class='title-nosotros-One'>Beex</span>",
                "<span class='title-nosotros-Two'>creativos</span>",
                "<span class='title-nosotros-Three'>soñadores</span>",
                "<span class='title-nosotros-Four'>los güais</span>",
                "<span class='title-nosotros-Five'>compañeros</span>",
              ]}
              typeSpeed={150}
              backSpeed={100}
              loop
            />
          </p>
          <p>que innovan con tecnología</p>
        </div>
        <p className="text-nosotros">
          Más que una empresa de tecnología, somos un equipo que lleva tatuada
          la palabra innovación al momento de crear soluciones omnicanales para
          mejorar las operaciones de atención al cliente, marketing, ventas y
          cobranzas de todas las empresas en el mundo.
        </p>
        <div className="container-burbujas">
          <div className="burbujaOne container-burbuja">
            <div className="burbujaOne-div burbuja-div" />
            <img src={burbujaOne} alt="burbuja" className="burbujaOne-img burbuja-img" />
          </div>
          <div className="burbujaTwo container-burbuja">
            <div className="burbujaTwo-div burbuja-div" />
            <img src={burbujaTwo} alt="burbuja" className="burbujaTwo-img burbuja-img" />
          </div>
          <div className="burbujaThree container-burbuja">
            <div className="burbujaThree-div burbuja-div" />
            <img src={burbujaThree} alt="burbuja" className="burbujaThree-img burbuja-img" />
          </div>
          <div className="burbujaFour container-burbuja">
            <div className="burbujaFour-div burbuja-div" />
            <img src={burbujaFour} alt="burbuja" className="burbujaFour-img burbuja-img" />
          </div>
        </div>
        <img src={foto} alt="foto" className="fotoequipo" />
      </div>
    </div>
  )
}

export default Intro
