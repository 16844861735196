import React from "react"
import img from "@components/pageNosotros/imgNosotros/img-adn/fondonegro.png"
import { Link } from "gatsby"

const ADN = ({ data }) => {
  return (
    <>
    <img src={img} alt="" className="fondo-adn" />
    <section className="section-adn">
      <p className="title-adn">¡Nuestro ADN! 🐳</p>
      {data.map((ele, i) => {
        return (
          <section key={i} className="card-adn">
            <div>
              <p className="card-adn-title">{ele.title}</p>
              <p className="card-adn-text">{ele.text}</p>
              
              {i === 0 ? <><br /> <Link className="card-adn-button" to="/nosotros/cultura-agile/">Descubre cómo lo hacemos</Link></> : ""}
            </div>
            <img src={ele.img} alt="" />
          </section>
        )
      })}
    </section>
    <img src={img} alt="" className="fondo-adn"/>
    </>
  )
}

export default ADN
