import React, { useState, useLayoutEffect } from "react"
import { Link } from "gatsby"
//Fotos Equipo
import MaxPhoto from "@components/pageNosotros/imgNosotros/img-teams/max_poster.png"
import FrankPhoto from "@components/pageNosotros/imgNosotros/img-teams/frank_poster.png"
import DanielPhoto from "@components/pageNosotros/imgNosotros/img-teams/daniel_poster.png"
import RaulPhoto from "@components/pageNosotros/imgNosotros/img-teams/raul_poster.png"
import JheysonPhoto from "@components/pageNosotros/imgNosotros/img-teams/jheyson_poster.png"
import LilianaPhoto from "@components/pageNosotros/imgNosotros/img-teams/liliana_poster.png"
import YeimiPhoto from "@components/pageNosotros/imgNosotros/img-teams/yeimi_poster.png"
import JulioPhoto from "@components/pageNosotros/imgNosotros/img-teams/julio_poster.png"
import RobinsonPhoto from "@components/pageNosotros/imgNosotros/img-teams/robinson_poster.png"
import HectorPhoto from "@components/pageNosotros/imgNosotros/img-teams/hector_poster.png"
import JorgePhoto from "@components/pageNosotros/imgNosotros/img-teams/jorge_poster.png"
import LuisAlcocerPhoto from "@components/pageNosotros/imgNosotros/img-teams/luisalcocer_poster.png"
import KatherinePhoto from "@components/pageNosotros/imgNosotros/img-teams/katherine_poster.png"
import MariTerePhoto from "@components/pageNosotros/imgNosotros/img-teams/maritere_poster.png"
import LuismiPhoto from "@components/pageNosotros/imgNosotros/img-teams/luismi_poster.png"
import GabrielaPhoto from "@components/pageNosotros/imgNosotros/img-teams/gabriela_poster.png"
import JairoPhoto from "@components/pageNosotros/imgNosotros/img-teams/jairo_poster.png"
import LuisSaezPhoto from "@components/pageNosotros/imgNosotros/img-teams/luissaez_poster.png"
import AndreePhoto from "@components/pageNosotros/imgNosotros/img-teams/andree_poster.png"
import GreysyPhoto from "@components/pageNosotros/imgNosotros/img-teams/greysy_poster.png"
import ElioPhoto from "@components/pageNosotros/imgNosotros/img-teams/elio_poster.png"
import JhonelPhoto from "@components/pageNosotros/imgNosotros/img-teams/jhonel_poster.png"
import SofhiaPhoto from "@components/pageNosotros/imgNosotros/img-teams/sofhia_poster.png"
import RichardPhoto from "@components/pageNosotros/imgNosotros/img-teams/richard_poster.png"
import JorgePalaciosPhoto from "@components/pageNosotros/imgNosotros/img-teams/jorgepalacios_poster.png"
import NelidaPhoto from "@components/pageNosotros/imgNosotros/img-teams/nelida_poster.png"
import NewPoster from "@components/pageNosotros/imgNosotros/img-teams/iconnew_poster.png"
import FondoPoster from "@components/pageNosotros/imgNosotros/img-teams/fondo_poster.png"

const dataTeams = [
  {
    title: "🐥 Management",
    teams: [
      {
        foto: MaxPhoto,
        name: "Max Aguirre Omonte",
        profession: "Chief Executive Officer & Cofounder",
      },
      {
        foto: FrankPhoto,
        name: "Frank Vásquez Ferré",
        profession: "Chief Marketing Officer & Cofounder",
      },
      {
        foto: DanielPhoto,
        name: "Daniel Tito Barrios",
        profession: "Chief Technology Officer",
      },
      {
        foto: RaulPhoto,
        name: "Raúl Ballesteros Samamé",
        profession: "Chief Operating Officer",
      },
      {
        foto: JheysonPhoto,
        name: "Jheyson Portocarrero Alfaro",
        profession: "Chief Product Officer",
      },
      {
        foto: NewPoster,
        name: "Tú",
        profession: "Postula aquí",
        link: true,
      },
    ],
    active: "active-teams",
  },
  {
    title: "🚀 Development",
    teams: [
      {
        foto: LilianaPhoto,
        name: "Liliana Uchalin Custodio",
        profession: "Lead Developer",
      },
      {
        foto: LuismiPhoto,
        name: "Luis Miguel Ventura Eugenio",
        profession: "Lead Developer",
      },
      {
        foto: ElioPhoto,
        name: "Elio Rodiguez Condori",
        profession: "Lead Developer",
      },
      {
        foto: JulioPhoto,
        name: "Julio Guarniz Ramirez",
        profession: "Voip and Cloud Specialist",
      },
      {
        foto: GabrielaPhoto,
        name: "Gabriela Ignacio Roque",
        profession: "Lead Frontend Developer",
      },
      {
        foto: YeimiPhoto,
        name: "Yeimi Omonte Chávez",
        profession: "FullStack Developer",
      },
      {
        foto: JairoPhoto,
        name: "Jairo Lachira Peralta",
        profession: "FullStack Developer",
      },
      {
        foto: SofhiaPhoto,
        name: "Sofhía Sánchez Rodríguez",
        profession: "UX/UI Specialist",
      },
      {
        foto: LuisSaezPhoto,
        name: "Luis Sáez Galindo",
        profession: "QA Analyst",
      },
      {
        foto: JhonelPhoto,
        name: "Jhonel Ríos Jaimes",
        profession: "Frontend Developer",
      },
      {
        foto: AndreePhoto,
        name: "Andree Anchi Dueñas",
        profession: "Backend Developer",
      },
      {
        foto: RichardPhoto,
        name: "Richard Salazar Quispe",
        profession: "Data & Process Specialist",
      },
      {
        foto: NewPoster,
        name: "Tú",
        profession: "Postula aquí",
        link: true,
      },
    ],
  },
  {
    title: "⭐ Marketing & Sales",
    teams: [
      {
        foto: JorgePhoto,
        name: "Jorge Bautista Mosquera",
        profession: "Digital Marketing Manager",
      },
      {
        foto: LuisAlcocerPhoto,
        name: "Luis Alcocer Gainza",
        profession: "Sales Manager",
      },
      {
        foto: KatherinePhoto,
        name: "Katherine Cantelmi Deffit",
        profession: "Sales Account Executive",
      },
      {
        foto: MariTerePhoto,
        name: "María Teresa Vásquez Ferré",
        profession: "Digital Marketing Analyst",
      },
      {
        foto: NewPoster,
        name: "Tú",
        profession: "Postula aquí",
        link: true,
      },
    ],
  },
  {
    title: "🏆 Customer Success",
    teams: [
      {
        foto: RobinsonPhoto,
        name: "Robinson Suárez Malca",
        profession: "Service Desk Leader",
      },
      {
        foto: HectorPhoto,
        name: "Héctor Lara Bernal",
        profession: "Service Desk Specialist",
      },
      {
        foto: GreysyPhoto,
        name: "Greysy Baldárrago Pachas",
        profession: "Customer Success Specialist",
      },
      {
        foto: NewPoster,
        name: "Tú",
        profession: "Postula aquí",
        link: true,
      },
    ],
  },
  {
    title: "📒 Finance",
    teams: [
      {
        foto: JorgePalaciosPhoto,
        name: "Jorge Palacios Barcena",
        profession: "Finance Manager",
      },
      {
        foto: NelidaPhoto,
        name: "Nélida Méndez Anaya",
        profession: "Administration Manager",
      },
      {
        foto: NewPoster,
        name: "Tú",
        profession: "Postula aquí",
        link: true,
      },
    ],
  },
]

const NavSupport = () => {
  //estado para el texto de la caja en desktop
  const [teams, settext] = useState([
    {
      foto: MaxPhoto,
      name: "Max Aguirre Omonte",
      profession: "Chief Executive Officer & Cofounder",
    },
    {
      foto: FrankPhoto,
      name: "Frank Vásquez Ferré",
      profession: "Chief Marketing Officer & Cofounder",
    },
    {
      foto: DanielPhoto,
      name: "Daniel Tito Barrios",
      profession: "Chief Technology Officer",
    },
    {
      foto: RaulPhoto,
      name: "Raúl Ballesteros Samamé",
      profession: "Chief Operating Officer",
    },
    {
      foto: JheysonPhoto,
      name: "Jheyson Portocarrero Alfaro",
      profession: "Chief Product Officer",
    },
    {
      foto: NewPoster,
      name: "Tú",
      profession: "Postula aquí",
      link: true,
    },
  ])

  const support = (event, text) => {
    event.preventDefault()
    settext([
      {
        foto: FondoPoster,
        name: "Tú",
        profession: "Postula aquí",
        link: true,
      },
    ])
    setTimeout(() => {
      settext(text)
    }, "100")
  }

  //Activar estilo para primer boton al cargar la pagina
  useLayoutEffect(() => {
    var header = document.getElementById("myDIV")
    var btns = header.getElementsByClassName("btn-support-teams")
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function() {
        var current = document.getElementsByClassName("active-teams")
        current[0].className = current[0].className.replace(" active-teams", "")
        this.className += " active-teams"
      })
    }
  })

  return (
    <section className="background-support" id="myDIV">
      <section className="section-teams-container">
        <div className="center-text container-title-teams-support" style={{textAlign: "center"}}>
          <p className="title-teams-support titles">Nuestro Power Team 💪🏻</p>
        </div>

        <section>
          <ul className="support">
            {dataTeams.map((elemento, i) => {
              return (
                <li key={i}>
                  <button
                    className={`btn-support-teams ${elemento.active}`}
                    onClick={event => {
                      support(event, elemento.teams)
                    }}
                  >
                    {elemento.title}
                  </button>
                </li>
              )
            })}
          </ul>
        </section>
        <section className="container-cards-teams">
          {teams.map((ele, i) => {
            return (
              <div key={i} className="card-teams">
                <img src={ele.foto} alt="foto" loading="lazy" />
                <p className="card-teams-accordion-name">{ele.name}</p>
                {ele.link ? (
                  <Link href="/jobs-beex/" className="link">
                    {ele.profession}
                  </Link>
                ) : (
                  <p className="card-teams-accordion-text">{ele.profession}</p>
                )}
              </div>
            )
          })}
        </section>
      </section>
    </section>
  )
}
export default NavSupport
