import React from "react"
import innovate from "@components/pageNosotros/imgNosotros/img-history/innovate.svg"

const CardTimeline = ({ elemento }) => {
  return (
    <div className="container-history">
      <img className="container-history-img" src={elemento.img} alt="galery" />
      <div>
        <p className="title-card-history">{elemento.title}</p>
        <p>{elemento.text}</p>
      </div>
    </div>
  )
}

const Timeline = ({ data }) => {
  const [completed, setCompleted] = React.useState(true),
    [currentStep, setStep] = React.useState(1),
    [labels, setLabels] = React.useState([
      "2014",
      "2016",
      "2018",
      "2019",
      "2020",
      "2022",
      "2023",
    ])

  return (
    <div className="section-container-history">
      <div className="center-text container-title-galery-support" style={{textAlign: "center"}}>
        <p className="title-galery-support titles">
          Nuestros hitos más importantes 📖{" "}
        </p>
      </div>
      <div className="greeting">
        {currentStep === 0 ? <CardTimeline elemento={data[0]} /> : ""}
        {currentStep === 1 ? <CardTimeline elemento={data[0]} /> : ""}
        {currentStep === 2 ? <CardTimeline elemento={data[1]} /> : ""}
        {currentStep === 3 ? <CardTimeline elemento={data[2]} /> : ""}
        {currentStep === 4 ? <CardTimeline elemento={data[3]} /> : ""}
        {currentStep === 5 ? <CardTimeline elemento={data[4]} /> : ""}
        {currentStep === 6 ? <CardTimeline elemento={data[5]} /> : ""}
        {currentStep === 7 ? <CardTimeline elemento={data[6]} /> : ""}
      </div>
      <Stepper
        labels={labels}
        setStep={setStep}
        completed={completed}
        currentStep={currentStep}
        setCompleted={setCompleted}
        labelUpDown={"labels-up"}
        labelAlign={"labels-nearby"}
      />
      <Controls
        setCompleted={setCompleted}
        currentStep={currentStep}
        completed={completed}
        setLabels={setLabels}
        setStep={setStep}
        labels={labels}
      />
    </div>
  )
}

const Stepper = ({
  labels,
  setStep,
  currentStep,
  completed,
  setCompleted,
  labelUpDown,
  labelAlign,
}) => {
  return (
    <div className={`stepper ${labelUpDown} ${labelAlign}`}>
      {labels.map((label, i) => (
        <Step
          selected={currentStep === i + 1}
          setCompleted={setCompleted}
          currentStep={currentStep}
          completed={completed}
          setStep={setStep}
          label={label}
          key={i}
          i={i}
        />
      ))}
    </div>
  )
}

const Step = ({
  label,
  i,
  setStep,
  selected,
  currentStep,
  completed,
  setCompleted,
}) => {
  // Switching Step
  function updateStep(i, completed, selected) {
    if (!selected) {
      setStep(++i)
      setCompleted(true)
    } else if (selected && !completed) {
      setCompleted(true)
    } else {
      setStep(i)
    }
  }

  return (
    <>
      <div
        className={
          "step-wrapper" +
          (selected && !completed ? " selected" : "") +
          (selected && completed ? " completed" : "") +
          (currentStep > i + 1 ? " passed" : "")
        }
      >
        <div
          onClick={() => updateStep(i, completed, selected)}
          className="dot"
        ></div>
        {label === "2020" ? (
          <img src={innovate} alt="innovate" className="img-innovate" />
        ) : (
          ""
        )}
        <div className="label">
          <span>{label}</span>
        </div>
      </div>
    </>
  )
}

const Controls = props => {
  // Current Step for short
  let current = props.currentStep

  // Shifting Step
  function stepShift(value) {
    if (current === 0 && !value) {
      console.log("Limit by Prev")
    } else if (current >= props.labels.length && props.completed && value) {
      console.log("Limit by Next")
    } else {
      if ((!props.completed && !value) || (props.completed && !value)) {
        --current
        props.setStep(current)
        props.setCompleted(true)
      } else if ((props.completed && value) || (!props.completed && value)) {
        ++current
        props.setStep(current)
        props.setCompleted(true)
      }
    }
  }

  return (
    <div className="controls">
      <button
        className={
          "btn-years btn-prev" + (current === 1 ? " btn-disabled" : "")
        }
        aria-label="history"
        onClick={e => (current === 1 ? e.preventDefault() : stepShift(false))}
      ></button>
      <button
        className={
          "btn-years btn-next" +
          (props.completed && current === props.labels.length
            ? " btn-disabled"
            : "")
        }
        aria-label="history"
        onClick={() => stepShift(true)}
      ></button>
    </div>
  )
}

export default Timeline
